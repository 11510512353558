import React, { useState, useContext } from "react"
import { Button, TextField } from "@material-ui/core"
import { FirebaseContext } from "../components/firebase"

import { navigate } from "gatsby"

export default function Home() {
  const [formValues, setFormValues] = useState({email: '', password: ''});
  const {firebase} = useContext(FirebaseContext);
  const [errorMessage, setErrorMessage] = useState('');
  
  function SubmitButton(){
    if (formValues.email && formValues.password){
      return <Button style={{ marginTop: "1em" ,'whiteSpace': 'nowrap', 'backgroundColor': '#FAD5AB'}} variant="outlined" type="submit" >Kirjaudu</Button>
    } else {
      return <Button disabled style={{ marginTop: "1em" ,'whiteSpace': 'nowrap', 'backgroundColor': '#FAD5AB'}} variant="outlined" type="submit">Kirjaudu</Button>
    };
  };

  function handleSubmit(e) {
     e.preventDefault(); 
      
      firebase.login({email: formValues.email, password: formValues.password})

      .then((result) => {
        window.localStorage.setItem("user", result.user.uid);
        navigate('/app/tervetuloa')})
      .catch(error => {
        console.log(error);
        setErrorMessage('Ei tuolla sisälle pääse. Naputtelepa tarkemmin.');
      });
    }
  
  function handleInputState(e) {
      e.persist();
      setErrorMessage('');
      setFormValues(currentValues => ({
          ...currentValues,
          [e.target.name]: e.target.value
      }));
  }
  
    return (
      <section>
        <form onSubmit={handleSubmit}>
          <TextField
            style={{ marginBottom: "1em" }}
            required
            value={formValues.email}
            name="email"
            onChange={handleInputState}
            placeholder="Käyttäjätunnus"
            type="email"
          />
          <TextField
            style={{ marginBottom: "1em" }}
            required
            value={formValues.password}
            name="password"
            onChange={handleInputState}
            placeholder="Salasana"
            type="password"
          />
          {!!errorMessage && <span style={{ color: "red" }}>{errorMessage}</span>}
          <SubmitButton >
          </SubmitButton>
        </form>
      </section>
    )
}




  

